import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const NavBar = ({ className, children, isSticky }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = /(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent);
      setIsMobile(isMobile);
    };

    // Verificar el tamaño de la ventana cuando se monta el componente
    handleResize();

    // Agregar listener al evento 'resize'
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stickyClasses = 'sticky top-0';
  const notStickyClasses = 'relative';

  return (
    <div
      className={classnames(
        isMobile
          ? 'bg-secondary-dark z-20 items-center border-b-0 border-black px-0'
          : 'bg-secondary-dark z-20 border-black px-1',
        isSticky && stickyClasses,
        !isSticky && notStickyClasses,
        className
      )}
      style={{
        paddingTop: isMobile ? '1px' : undefined,
        paddingBottom: isMobile ? '0px' : undefined,
        maxHeight: isMobile ? '54px' : undefined,
        minWidth: isMobile ? '360px' : undefined,
      }}
    >
      {children}
    </div>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSticky: PropTypes.bool,
};

export default NavBar;
