import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import Tooltip from '../Tooltip';

const borderSize = 1;
const collapsedWidth = 25;
const closeIconWidth = 30;
const gridHorizontalPadding = 1;
const tabSpacerWidth = 2;

const baseClasses =
  'transition-all duration-100 ease-in-out h-1000 justify-start box-content flex flex-col';

const classesMap = {
  open: {
    left: `mr-1`,
    right: `ml-1`,
  },
  closed: {
    left: `mr-1 items-end`,
    right: `ml-2 items-start`,
  },
};

const openStateIconName = {
  left: 'side-panel-close-left',
  right: 'side-panel-close-right',
};

const getTabWidth = numTabs => {
  return numTabs < 3 ? 68 : 40;
};

const getGridWidth = (numTabs, gridAvailableWidth) => {
  const spacersWidth = (numTabs - 1) * tabSpacerWidth;
  const tabsWidth = getTabWidth(numTabs) * numTabs;

  return gridAvailableWidth > tabsWidth + spacersWidth
    ? tabsWidth + spacersWidth
    : gridAvailableWidth;
};

const getNumGridColumns = (numTabs, gridWidth) => {
  if (numTabs === 1) {
    return 1;
  }

  const tabWidth = getTabWidth(numTabs);
  const numTabsWithOneSpacerEach = Math.floor(gridWidth / (tabWidth + tabSpacerWidth));

  return (numTabsWithOneSpacerEach + 1) * tabWidth + numTabsWithOneSpacerEach * tabSpacerWidth <=
    gridWidth
    ? numTabsWithOneSpacerEach + 1
    : numTabsWithOneSpacerEach;
};

const getGridStyle = (side, numTabs = 0, gridWidth, expandedWidth) => {
  const relativePosition = Math.max(0, Math.floor(expandedWidth - gridWidth) / 2 - closeIconWidth);
  return {
    position: 'relative',
    ...(side === 'left' ? { right: `${relativePosition}px` } : { left: `${relativePosition}px` }),
    width: `${gridWidth}px`,
  };
};

const getTabClassNames = (numColumns, numTabs, tabIndex, isActiveTab, isTabDisabled) =>
  classnames('h-[28px] mb-[2px] cursor-pointer text-white', {
    'hover:text-primary-active': !isActiveTab && !isTabDisabled,
    'rounded-l': tabIndex % numColumns === 0,
    'rounded-r': (tabIndex + 1) % numColumns === 0 || tabIndex === numTabs - 1,
  });

const getTabStyle = numTabs => ({
  width: `${getTabWidth(numTabs)}px`,
});

const getTabIconClassNames = isActiveTab =>
  classnames('h-full w-full flex items-center justify-center', {
    'bg-customblue-40': isActiveTab,
    rounded: isActiveTab,
  });

const createStyleMap = (expandedWidth, borderSize, collapsedWidth) => {
  const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;

  return {
    open: {
      left: { marginLeft: '0px' },
      right: { marginRight: '0px' },
    },
    closed: {
      left: { marginLeft: `-${collapsedHideWidth}px` },
      right: { marginRight: `-155px` },
    },
  };
};

const getToolTipContent = (label, disabled) => (
  <>
    <div>{label}</div>
    {disabled && <div className="text-white">{'Not available based on current context'}</div>}
  </>
);

const createBaseStyle = (expandedWidth, isMobile) => ({
  maxWidth: isMobile ? '100%' : `${expandedWidth}px`,
  width: isMobile ? '100%' : `${expandedWidth}px`,
  position: isMobile ? 'absolute' : 'relative',
  bottom: isMobile ? '215px' : undefined,
  height: isMobile ? '50px' : '99.5%',
  top: isMobile ? undefined : '0.5%',
  zIndex: isMobile ? 100 : undefined,
  background: isMobile ? 'rgba(0, 0, 0, 0.01)' : undefined,
});

const SidePanel = ({
  side,
  className,
  activeTabIndex: activeTabIndexProp = null,
  tabs,
  onOpen,
  expandedWidth = 148,
  onActiveTabIndexChange,
}) => {
  const { t } = useTranslation('SidePanel');

  const [panelOpen, setPanelOpen] = useState(activeTabIndexProp !== null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [seriesOpen, setSeriesOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = /(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
      setSeriesOpen(false);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styleMap = createStyleMap(expandedWidth, borderSize, collapsedWidth);
  const baseStyle = createBaseStyle(expandedWidth, isMobile);
  const gridAvailableWidth = expandedWidth - closeIconWidth - gridHorizontalPadding;
  const gridWidth = getGridWidth(tabs.length, gridAvailableWidth);
  const openStatus = panelOpen ? 'open' : 'closed';
  const style = Object.assign({}, styleMap[openStatus][side], baseStyle);

  const updatePanelOpen = useCallback(
    panelOpen => {
      setPanelOpen(panelOpen);
      if (panelOpen && onOpen) {
        onOpen();
      }
    },
    [onOpen]
  );

  const updateSeriesOpen = useCallback(() => {
    setSeriesOpen(prevSeriesOpen => !prevSeriesOpen);
  }, []);

  const updateActiveTabIndex = useCallback(
    activeTabIndex => {
      if (activeTabIndex === null) {
        updatePanelOpen(false);
        return;
      }

      setActiveTabIndex(activeTabIndex);
      updatePanelOpen(true);

      if (onActiveTabIndexChange) {
        onActiveTabIndexChange({ activeTabIndex });
      }
    },
    [onActiveTabIndexChange, updatePanelOpen]
  );

  useEffect(() => {
    updateActiveTabIndex(activeTabIndexProp);
  }, [activeTabIndexProp, updateActiveTabIndex]);

  const getCloseStateComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div
          className={classnames(
            'flex h-[20px] w-full cursor-pointer items-center rounded-md',
            side === 'left' ? 'justify-end pr-2' : 'justify-start pl-2'
          )}
          onClick={() => {
            updatePanelOpen(!panelOpen);
          }}
          data-cy={`side-panel-header-${side}`}
        >
          <Icon
            name={'navigation-panel-right-reveal'}
            className={classnames('text-primary-active', side === 'left' && 'rotate-180 transform')}
          />
        </div>
        <div className={classnames('mt-3 flex flex-col space-y-3')}>
          {_childComponents.map((childComponent, index) => (
            <Tooltip
              position={side === 'left' ? 'right' : 'left'}
              key={index}
              content={getToolTipContent(childComponent.label, childComponent.disabled)}
              className={classnames(
                'flex items-center',
                side === 'left' ? 'justify-end' : 'justify-start'
              )}
            >
              <div
                id={`${childComponent.name}-btn`}
                data-cy={`${childComponent.name}-btn`}
                className="text-primary-active hover:cursor-pointer"
                onClick={() => {
                  return childComponent.disabled ? null : updateActiveTabIndex(index);
                }}
              >
                <Icon
                  name={childComponent.iconName}
                  className={classnames({
                    'text-primary-active': true,
                    'ohif-disabled': childComponent.disabled,
                  })}
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </div>
            </Tooltip>
          ))}
        </div>
      </>
    );
  };

  const getCloseIcon = () => (
    <div
      className={classnames(
        'flex h-[28px] cursor-pointer items-center justify-center',
        side === 'left' ? 'order-last' : 'order-first'
      )}
      style={{ width: `${closeIconWidth}px` }}
      onClick={() => {
        updatePanelOpen(!panelOpen);
      }}
      data-cy={`side-panel-header-${side}`}
    >
      <Icon
        name={openStateIconName[side]}
        className="text-primary-active"
      />
    </div>
  );

  const getCloseIconMobile = () => (
    <div
      className={classnames('cursor-pointer items-center justify-center')}
      style={{ position: 'absolute', marginLeft: '44.5%', bottom: '-130px' }}
      onClick={() => {
        updateSeriesOpen();
      }}
    >
      <Icon
        name="ver2"
        style={{ width: '40px', height: '40px' }}
      />
    </div>
  );

  const getOpenIconMobile = () => (
    <div
      className={classnames('cursor-pointer items-center justify-center')}
      style={{ position: 'absolute', marginLeft: '44.5%', bottom: '-130px' }}
      onClick={() => {
        updateSeriesOpen();
      }}
    >
      <Icon
        name="ver1"
        style={{ width: '40px', height: '40px', opacity: '0.6' }}
      />
    </div>
  );

  const getTabGridComponent = () => {
    const numCols = getNumGridColumns(tabs.length, gridWidth);

    return (
      <div className={classnames('flex grow', side === 'right' ? 'justify-start' : 'justify-end')}>
        <div
          className={classnames('text-primary-active flex flex-wrap')}
          style={getGridStyle(side, tabs.length, gridWidth, expandedWidth)}
        >
          {tabs.map((tab, tabIndex) => {
            const { disabled } = tab;
            return (
              <React.Fragment key={tabIndex}>
                {tabIndex % numCols !== 0 && (
                  <div className={classnames('flex h-[28px] w-[2px] items-center')}>
                    <div className="h-[20px] w-full"></div>
                  </div>
                )}
                <Tooltip
                  position={'bottom'}
                  key={tabIndex}
                  content={getToolTipContent(tab.label, disabled)}
                >
                  <div
                    className={getTabClassNames(
                      numCols,
                      tabs.length,
                      tabIndex,
                      tabIndex === activeTabIndex,
                      disabled
                    )}
                    style={getTabStyle(tabs.length)}
                    onClick={() => {
                      return disabled ? null : updateActiveTabIndex(tabIndex);
                    }}
                    data-cy={`${tab.name}-btn`}
                  >
                    <div className={getTabIconClassNames(tabIndex === activeTabIndex)}>
                      <Icon
                        name={tab.iconName}
                        className={`${disabled && 'ohif-disabled'}`}
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      ></Icon>
                    </div>
                  </div>
                </Tooltip>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const getOneTabComponent = () => (
    <div
      className={classnames(
        'text-primary-active flex grow cursor-pointer select-none justify-center self-center text-[13px]'
      )}
      style={{
        ...(side === 'left'
          ? { marginLeft: `${closeIconWidth}px` }
          : { marginRight: `${closeIconWidth}px` }),
      }}
      data-cy={`${tabs[0].name}-btn`}
      onClick={() => updatePanelOpen(!panelOpen)}
    >
      <span>{tabs[0].label}</span>
    </div>
  );

  const getOpenStateComponent = () => (
    <div className="bg-primary-dark flex select-none rounded-t pt-1.5 pb-[2px]">
      {getCloseIcon()}
      {tabs.length === 1 ? getOneTabComponent() : getTabGridComponent()}
    </div>
  );

  return (
    <div
      className={classnames(className, baseClasses, classesMap[openStatus][side])}
      style={style}
    >
      {isMobile ? (
        seriesOpen ? (
          <div id="images">
            {tabs.map((tab, tabIndex) => {
              if (tabIndex === activeTabIndex) {
                return <tab.content key={tabIndex} />;
              }
              return null;
            })}
            {getCloseIconMobile()}
          </div>
        ) : (
          <div id="bottom-images">{getOpenIconMobile()}</div>
        )
      ) : panelOpen ? (
        <>
          {getOpenStateComponent()}
          {tabs.map((tab, tabIndex) => {
            if (tabIndex === activeTabIndex) {
              return <tab.content key={tabIndex} />;
            }
            return null;
          })}
        </>
      ) : (
        <React.Fragment>{getCloseStateComponent()}</React.Fragment>
      )}
    </div>
  );
};

SidePanel.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        iconLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        content: PropTypes.func,
      })
    ),
  ]),
  onOpen: PropTypes.func,
  onActiveTabIndexChange: PropTypes.func,
  expandedWidth: PropTypes.number,
};

export default SidePanel;
