import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
// import HeaderPatientInfo from '../HeaderPatientInfo'; // No se utiliza
// import { PatientInfoVisibility } from '../../types/PatientInfoVisibility'; // No se utiliza

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  // showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED, // No se utiliza
  // servicesManager, // No se utiliza
  // Secondary, // No se utiliza
  // appConfig, // No se utiliza
  ...props
}) {
  const { t } = useTranslation('Header');
  const [isMobile, setIsMobile] = useState(false);
  const [studyInstanceUID, setStudyInstanceUID] = useState(null);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = /(Mobi|Android|iPhone|iPad|iPod)/.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Extraer StudyInstanceUIDs de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('StudyInstanceUIDs');
    if (uid) {
      setStudyInstanceUID(uid);
    } else {
      console.error('StudyInstanceUIDs not found in URL');
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Buscamos si existe una interpretación con el StudyInstanceUID
    if (studyInstanceUID) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://app.laughing-grothendieck.74-208-159-220.plesk.page/interpretacion`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const data = await response.json();

          if (data && data.rows && data.rows.length > 0) {
            const matchingRow = data.rows.find(row => row.uid === studyInstanceUID);
            if (matchingRow) {
              setApiData({
                interpretacion: matchingRow.interpretacion_medica_si,
              });
            } else {
              setApiData(null);
            }
          } else {
            setApiData(null);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setApiData(null);
        }
      };
      fetchData();
    }
  }, [studyInstanceUID]);

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  // Construir la URL del enlace
  const linkURL = studyInstanceUID
    ? `https://sys.diagnosticolomas.com/generate-pdf-visor/${studyInstanceUID}`
    : '#';

  return (
    <>
      {isMobile ? (
        <NavBar
          className="row"
          isSticky={isSticky}
          {...props}
        >
          <div style={{ display: 'flex' }}>
            {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo-ohif" />}
          </div>
          <div
            className="col d-flex align-items-end"
            style={{
              marginLeft: 'auto',
            }}
          >
            {apiData && apiData.interpretacion ? (
              <a
                href={linkURL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                Interpretación
              </a>
            ) : (
              <span style={{ color: 'white' }}>Interpretación - Pendiente</span>
            )}
          </div>
          <div
            className="col d-flex justify-content-center align-items-center bg-secondary-dark"
            style={{
              position: 'relative',
              top: '24px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </div>
        </NavBar>
      ) : (
        <NavBar
          isSticky={isSticky}
          {...props}
        >
          <div className="relative h-[48px] items-center">
            <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center">
              <div
                className={classNames(
                  'mr-3 inline-flex items-center',
                  isReturnEnabled && 'cursor-pointer'
                )}
                onClick={onClickReturn}
                data-cy="return-to-work-list"
              >
                {isReturnEnabled && (
                  <Icon
                    name="chevron-left"
                    className="text-primary-active w-8"
                  />
                )}
                <div className="ml-1">
                  {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo-ohif" />}
                </div>
              </div>
            </div>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <div className="flex items-center justify-center space-x-2">
                {apiData && apiData.interpretacion ? (
                  <>
                    <a
                      href={linkURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'white' }}
                    >
                      Interpretación
                    </a>
                  </>
                ) : (
                  <span style={{ color: 'white' }}>Interpretación - Pendiente</span>
                )}
                {children}
              </div>
            </div>
            <div className="absolute right-0 top-1/2 flex -translate-y-1/2 select-none items-center"></div>
          </div>
        </NavBar>
      )}
    </>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  // showPatientInfo: PropTypes.string, // No se utiliza
  // servicesManager: PropTypes.object, // No se utiliza
};

export default Header;
